.rbc-header {
  border-bottom: 0;
}

.rbc-header * {
  font-weight: bold;
}

.rbc-allday-cell {
  height: 0 !important;
  border-color: transparent !important;
  border-width: 0;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-event {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: 0 !important;
  background: transparent;
}

.rbc-event-label {
  display: none;
}

.rbc-selected {
  background: transparent !important;
}
